window.app = window.app || {};
window.app.pageHandler = (function() {

	var inited = false,
		$header,
		$nav,
		wScrollTop = 0,
		wHeight= $(window).height(),
		$w = $(window);

	function init() {
		if (inited) {return false;}

		$header = $('#header');
		$nav = $('#nav');

		generalEvents();
		snow();

		scrollHandler();
		$w.on('scroll', function() {
			scrollHandler();
		});

		inited = true;
	}

	function closeMenu() {
		$('#nav-trigger').prop('checked', false);
	}

	function scrollUp() {
		TweenMax.to($('html, body'), 1.3, {scrollTop: 0, ease: Cubic.easeInOut});
	}

	function anchorHandler(url) {
		if (!url || url.indexOf('#') < 0 || url.split('#')[1].length === 0) {return false;}
		if (url.split('#')[0] != window.location.href.split('#')[0]) {return false;}

		var hash = '#' + url.split('#')[1],
			$anchor = $(hash + ', [data-anchor="' + hash.slice(1) + '"]');

		if ($anchor.length > 0) {
			TweenMax.to($('html, body'), 1.3, {scrollTop: $anchor.offset().top - 100, ease: Cubic.easeInOut});

			return true;
		} else {
			//TweenMax.to($('html, body'), .5, {scrollTop: 0, ease: Cubic.easeIn});
		}
	}

	function generalEvents() {
		$('body')
		// Scroll To Top
		.on('click', '.scrolltop', scrollUp)
		// Scroll to anchor point
		.on('click', 'a[href^="#"]:not([href="#"])', function(e) {
			var handled = anchorHandler(this.href);
			$('.mobile-nav input').prop('checked', false);
			$('body').removeClass('mobile-open');
			$('.header').removeClass('opened');
			$('.mobile-nav').removeClass('opened');
			if (handled) {
				e.stopPropagation();
				e.preventDefault();
				this.blur();
				return false;
			}
			return true;
		})
		// close menu by click outside
		.on('click', '#header > .bg', function(e) {
			closeMenu();
		});

		// video
		$(document).on('click', '[data-playvideo]', function(){
			let $this = $('[data-playvideo]');
			let $video = $this.attr('data-playvideo');
			let $player = `<iframe src="https://www.youtube.com/embed/`+$video+`?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
			$this.parent().html($player)
		});
	}

	function scrollHandler() {

// 		function handleScroll() {
  //   const sections = document.querySelectorAll('section.move');
  //   const viewportHeight = window.innerHeight;

  //   sections.forEach(section => {
  //     const rect = section.getBoundingClientRect();
  //     const visibleHeight = Math.max(0, Math.min(viewportHeight, rect.bottom) - Math.max(0, rect.top));

  //     if (visibleHeight >= viewportHeight * 0.7) {
  //       section.style.opacity = 1;
  //     } else if (visibleHeight <= viewportHeight * 0.2) {
  //       section.style.opacity = 0
  //     } else {
  //       const opacity = (visibleHeight / (viewportHeight * 0.5)) * 0.5;
  //       section.style.opacity = opacity;
  //       section.style.marginRight = opacity * 100;
  //     }
  //   });
  // }

  // window.addEventListener('scroll', handleScroll);
  // window.addEventListener('resize', handleScroll); // újraszámítás ablak méretezésekor
  // handleScroll(); // kezdeti ellenőrzés betöltéskor

		// const scrollSpeed = 0.8;
		// let currentScroll = window.scrollY;
		// let targetScroll = window.scrollY;

		// function smoothScroll() {
		//     currentScroll += (targetScroll - currentScroll) * scrollSpeed;
		//     window.scrollTo(0, currentScroll);

		//     if (Math.abs(targetScroll - currentScroll) > 0.5) {
		//         requestAnimationFrame(smoothScroll);
		//     }
		// }

		// window.addEventListener('scroll', () => {
		//     targetScroll = window.scrollY;
		//     if (Math.abs(targetScroll - currentScroll) > 0.5) {
		//         requestAnimationFrame(smoothScroll);
		//     }
		// });

		window.addEventListener('scroll', function() {
			const scrollPosition = window.scrollY;
			const background = document.querySelector('#section-bg');
			const content = document.querySelectorAll('section.move');

			if ( scrollPosition > 50 ) {
				$('.header').addClass('scroll')
			} else {
				$('.header').removeClass('scroll')
			}

			var wW = window.innerWidth;
			var pref = 100;

			if (wW < 1400 ) pref = 150;
			if (wW < 1200 ) pref = 120;



			// Lassabb mozgás a háttérképnek
			background.style.transition = '0.2s';
			background.style.transitionDelay = '0s';
			background.style.transform = `translateY(${scrollPosition * 0.03 + (  pref - window.innerWidth * 0.015) }px)`;

		});





		$(document).on("scroll", onScroll);
		function onScroll(event){
			var scrollPos = $(document).scrollTop();
			$('header nav a').each(function () {
				var currLink = $(this);
				var refElement = $(currLink.attr("href")).closest('section');
				if (!refElement.length) {
					return
				}

				if (refElement.position().top - window.innerHeight < scrollPos) {
					refElement.addClass('anim-in')
				}
				if (refElement.position().top - 150 <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
					$('header nav a').removeClass("active");
					currLink.addClass("active");
				}
				else{
					currLink.removeClass("active");
				}
			});
		}

		wScrollTop = $(window).scrollTop();



		if (!SMALLSCREEN) {
		
			if (wScrollTop > wHeight / 10) {
				$header.addClass('sticky');
				$nav.addClass('sticky');
			} else {
				$header.removeClass('sticky');
				$nav.removeClass('sticky');
			}
		
		}
	}

	function parallax(){

	}

	function snow(){
		// let snowCounter = 0;
		// setInterval(function(){

		//   if ( snowCounter > 20) return;

		//   var documentHeight = $(document).height();
		//   var startPositionLeft = Math.random() * $(document).width() - 100;
		//   var startOpacity = 0.5 + Math.random();

		//   var endPositionTop = $('.visual').height() + 300 ;
		//   var endPositionLeft = startPositionLeft - 100 + Math.random() * 200;
		//   var durationFall = 5000 + Math.random() * 5000;

		//   var animationFlake = endPositionLeft > startPositionLeft ? 'clockwise' : 'counterclockwise';
		//   var sizeFlake = 15 + Math.random() * 20;

		//   snowCounter++;

		//   $('.snowflake').clone()
		// 	.attr('class', null)
		// 	.attr('class', 'snow-flake')
		// 	.appendTo('section.visual')
		// 	.css({
		// 	left: startPositionLeft,
		// 	opacity: startOpacity,
		// 	'animation': 'spin-' + animationFlake + ' 6s linear infinite',
		// 	'font-size': sizeFlake
		//   })
		// 	.animate({
		// 	top: endPositionTop,
		// 	left: endPositionLeft,
		// 	opacity: 0.2
		//   },
		//  durationFall,
		//  'linear',
		//  function() {
		// 	snowCounter--;
		// 	$(this).remove();
		//   });
			
		// }, 1500);
	}

	return {
		init: init,
		closeMenu: closeMenu,
		scrollUp: scrollUp,
	};

})();
